import React from 'react';
import SkillSlot from './SkillSlot.js'
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import NavigationHeader from './NavigationHeader.js';

class SkillPage extends React.Component {

  	constructor(props) {
    	super(props);
      this.state = {skills: [], loaded: false}
  	}

    componentDidMount(){
      fetch("https://7dnw1ligd1.execute-api.us-east-1.amazonaws.com/Personal-Website-Prod/skills")
        .then(res => res.json())
        .then(
          (result) => {
            var skillArray = JSON.parse(result['skills'])
            var projectsArray = skillArray.map(x => x['projects'])
            var promiseArray = []
            for ( var i = 0; i < projectsArray.length; i++) {
               let projects = projectsArray[i]
               let projectPromiseArray = []
               for ( var j = 0; j < projects.length; j++){
                let projectId = projects[j]
                projectPromiseArray.push(
                  fetch("https://7dnw1ligd1.execute-api.us-east-1.amazonaws.com/Personal-Website-Prod/projects/" + projectId)
                    .then((res) => res.json())
                    .then((res) => {
                      return JSON.parse(res['projectJson'])
                    },
                    (error) => console.log(error)
                    )
                  )
               }
               promiseArray.push(projectPromiseArray)

               let allJson = Promise.all(promiseArray.map(x => Promise.all(x)))
               allJson
                .then(res => 
                {
                  let skillList = []
                  for (var i =0; i< res.length; i++){
                    let skillName = skillArray[i].title
                    let projectList = res[i]
                    skillList.push({title: skillName, projectList: projectList})
                  }
                  this.setState({skills: skillList, loaded : true})
                })
            }
            
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.log(error)
          }
        )      
    }

  	render(){
      if(!this.state.loaded){
        return null;
      }
    var skills = this.state.skills
    return (
      <div>
        <NavigationHeader/>
        <div style={{paddingTop : "65px"}}>
          {skills.map((skill) => (
            <Container style={{paddingTop : "25px", paddingBottom : "25px"}}>
              <SkillSlot title={skill.title} projects={skill.projectList}/>
            </Container >            
            ))}
        </div>
      </div>
    );
  	}

}

export default SkillPage;