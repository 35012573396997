import React from 'react';
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

class HomePage extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
	  return (
	    <div className="App">
	      <header className="App-header">
	        <h2>
	          Michael X Li
	        </h2>
	        <div style={{display: 'flex'}}>
				<Typography style={{ marginLeft: "15px"}} variant="h6">
					<Link href="/about" color="inherit">
    					About
  					</Link>
				</Typography>
				<Typography style={{ marginLeft: "15px"}} variant="h6">
					<Link href="/projects" color="inherit">
    					Projects
  					</Link>
				</Typography>
				<Typography style={{ marginLeft: "15px"}} variant="h6">
					<Link href="/skills" color="inherit">
    					Skills
  					</Link>
				</Typography>
				<Typography style={{ marginLeft: "15px"}} variant="h6">
					<Link href="/essays" color="inherit">
    					Essays
  					</Link>
				</Typography>
	        </div>
	      </header>
	    </div>
	    )
	}
}

export default HomePage