import React from 'react';
import logo from './logo.svg';
import './App.css';
import ProjectPage from './ProjectPage.js';
import SkillSlot from './SkillSlot.js';
import AboutSlot from './AboutSlot.js';
import Essay from './Essay.js';
import EssayPage from './EssayPage.js';
import NavigationHeader from './NavigationHeader.js';
import SkillPage from './SkillPage';
import AboutPage from './AboutPage.js'
import HomePage from './HomePage.js'

import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom'

function App() {
  /*
  return (
    <div className="App">
      <header className="App-header">
        <h2>
          Michael Li
        </h2>
          About  Projects  Skills  Essays
      </header>
    </div>
  );
  */
  //return <ProjectPage/>
  //return <ProjectPage/>
  //return <SkillSlot/>
  //return <Essay/>
  //return <EssayPage/>
  return (
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={HomePage} />
          <Route exact path='/about' component={AboutPage} />
          <Route exact path='/projects' component={ProjectPage} />
          <Route exact path='/skills' component={SkillPage} />
          <Route exact path='/essays' component={EssayPage} />
          <Route exact path='/essays/:essayId' component={Essay} />
        </Switch>
      </BrowserRouter>
    )
}   

export default App;
