import React from 'react';
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import ProjectTile from "./ProjectTile.js";
import NavigationHeader from './NavigationHeader.js';

class ProjectPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {projects: [], loaded: false}
  }

  getHeaderStyle = () => {
    return {
      "text-align": "left"
    }
  }

  componentDidMount() {
    fetch("https://7dnw1ligd1.execute-api.us-east-1.amazonaws.com/Personal-Website-Prod/projects")
      .then(res => res.json())
        .then(
          (result) => {
            this.setState({projects: JSON.parse(result['projects']), loaded: true})
          },
          (error) => {
            console.log(error)
          }
        )


  }

  render(){

    var projects = this.state.projects
    console.log(projects)
    //height 350px, width, 400px
    return (
      <div>
        <NavigationHeader/>
        <div style={{paddingTop : "65px"}}>
          <Container maxWidth="md">
            <Grid container spacing={3}>

              {projects.map((project) => (
                <Grid item>
                  <ProjectTile height="350px" width="400px" title={project.title} image={project.image} link={project.link}/>
                </Grid>
                ))}
            </Grid>
          </Container>
        </div>
      </div>
    );
  }
}

export default ProjectPage;
