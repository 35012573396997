import React from 'react';
import NavigationHeader from './NavigationHeader.js';
import AboutSlot from './AboutSlot.js';

class AboutPage extends React.Component {
	constructor(props){
		super(props)
	}

	render(){
	  return (
		  <div>
	        <NavigationHeader/>
	        <div style={{paddingTop : "100px"}}>
	        	<AboutSlot/>
	        </div>
	      </div>
	      )

	}

}

export default AboutPage;