import React from 'react';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import ProjectTile from "./ProjectTile.js";

class SkillSlot extends React.Component {

  	constructor(props) {
    	super(props);
      console.log(this.props.projects)
    	this.state = { color: "white", width: "80%", height: "350px", title: this.props.title, projects: this.props.projects}
  	}

	getSlotStyle = () => {
  		return {
  		  margin: "auto",
  		  background: this.state.color,
  		  width: this.state.width,
  		  height: this.state.height
  		}
  	}

  	getGridStyle = () => {
  		return {
  		  padding: "15px"
  		}
  	}

  	render(){

  		//Paper, with title, divider, and then grid with project tiles
      var projects = this.state.projects
  		return <Card style={this.getSlotStyle()}>
  			<CardHeader title={this.state.title} style={{ textAlign:'center' , height: "30px"}}> </CardHeader>
  			<Divider/>
  			<Grid container spacing={3} alignItems="center" style ={this.getGridStyle()}>
          {projects.map(project => (
            <Grid item>
              <ProjectTile height="100px" width="100px" title={project.title} image={project.image} link={project.link}/>
            </Grid>     
            ))} 				
  			</Grid>
  		</Card>
  	}
}

export default SkillSlot;