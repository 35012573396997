import React from 'react';
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
class AboutSlot extends React.Component {

  	constructor(props) {
    	super(props);
    	this.state = { color: "white", width: "600px", height: "260px"}
  	}

	getSlotStyle = () => {
  		return {
  		  margin: "auto",
  		  display: "flex",
  		  justifyContent: "left",
  		  background: this.state.color,
  		  width: this.state.width,
  		  height: this.state.height
  		}
  	}

  	getGridStyle = () => {
  		return {
  		  padding: "15px"
  		}
  	}

  	getProfileStyle = () => {
  		return {
  			"width" : "200px",
  			"height": "260px",
  			"verticalAlign": "center",
  			"alignItems" : "center",
  			"display": "flex",
  			"justifyContent": "center"
  		}
  	}

  	getParagraphStyle = () => {
  		return {
  			"width" : "400px",
  			"height": "260px",
  			"paddingLeft" : "40px",
  			"paddingTop" : "50px"
  		}
  	}

  	getTitleStyle = () => {
  		return {
  			"width" : "400px"
  		}
  	}

  	getWritingStyle = () => {
  		return {
  			"width" : "320px",
  			"height" : "160px",
  			"border" : "none",
  			"boxShadow" : "none"
  		}
  	}
  	getPicStyle = () => {
  		return {
  			"width" : "175px",
  			"height": "175px",
  			"borderRadius" : "50%",

  		}
  	}

  	render(){

  		//Paper, with title, divider, and then grid with project tiles
  		return <Card style={this.getSlotStyle()}>
  				<Card style={this.getParagraphStyle()}>
  					<Card style={this.getWritingStyle()}>
  						<CardHeader title="About Me" style={{ textAlign:'center', height: "5px"}}> </CardHeader>
  						<CardContent style={{paddingTop: "0px"}}> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse quis tincidunt tellus. Integer imperdiet justo fermentum laoreet mattis. Curabitur vitae magna a mi mat</CardContent>
  					</Card>
  				</Card>
  				<Card style={this.getProfileStyle()}>
  					<img style={this.getPicStyle()} src="./selfie.png"/>
  				</Card>
  				
  		</Card>
  	}
}

export default AboutSlot;