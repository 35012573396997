import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import ToolBar from '@material-ui/core/ToolBar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

class NavigationHeader extends React.Component {
	constructor(props){
		super(props)
		this.state = {navPadding: 90}
	}

	render(){
		return (
		<AppBar style = {{ alignItems: "center" , background : "white", color : "black", "boxShadow" : "0px .25px"}}>
			<ToolBar>
				<Typography style={{ marginRight: this.state.navPadding }} variant="h6">
					<Link href="/about" color="inherit">
    					About
  					</Link>
				</Typography>
				<Typography style={{ marginRight: this.state.navPadding }} variant="h6">
					<Link href="/projects" color="inherit">
    					Projects
  					</Link>
				</Typography>
				<Typography style={{ marginRight: this.state.navPadding }} variant="h6">
					<Link href="/skills" color="inherit">
    					Skills
  					</Link>
				</Typography>
				<Typography style={{ marginRight: this.state.navPadding }} variant="h6">
					<Link href="/essays" color="inherit">
    					Essays
  					</Link>
				</Typography>
			</ToolBar>
		</AppBar>
		)
	}

}
export default NavigationHeader;