import React from 'react';
import axios from "axios";
import NavigationHeader from './NavigationHeader.js';
import Typography from '@material-ui/core/Typography';

import {
  Link
} from 'react-router-dom'
class EssayPage extends React.Component {
	constructor(props){
		super(props)
		this.state = {essays: [], loaded: false}
	}

    componentDidMount(){
	    fetch("https://7dnw1ligd1.execute-api.us-east-1.amazonaws.com/Personal-Website-Prod/essays")
	      .then(res => res.json())
	      .then(
	        (result) => {
	          this.setState({essays: JSON.parse(result['Essays']), loaded: true})
	        },
	        // Note: it's important to handle errors here
	        // instead of a catch() block so that we don't swallow
	        // exceptions from actual bugs in components.
	        (error) => {
	          console.log(error)
	        }
	      )
    }

	render(){
	  if(!this.state.loaded){
	  	return null
	  }
	  var essays = this.state.essays
	  return (
		  <div>
	        <NavigationHeader/>
	        <div style={{paddingTop : "65px", paddingLeft: "50px"}}>
	        		{essays.map((essay) => (
	        			<Typography variant="h6" color="black">
	        			<Link to={"/essays/" + essay} color="inherit" underline="none">
	        			  {essay}
	        			</Link>
	        			</Typography>
	        		))}
	        </div>
	      </div>
	      )

	}

}

export default EssayPage;