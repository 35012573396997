import React from 'react';
import NavigationHeader from './NavigationHeader.js';
import ReactMarkdown from 'react-markdown';

class Essay extends React.Component {
	constructor(props){
		super(props)
		this.state = {essayId : "", loaded : false}
	}

	componentDidMount() {
		const {match: {params}} = this.props;
		fetch("https://7dnw1ligd1.execute-api.us-east-1.amazonaws.com/Personal-Website-Prod/essays/".concat(params.essayId),
			)
	      .then(res => res.json())
	      .then(
	        (result) => {
	          this.setState({essayId: params.essayId, markdownBody: result.markdownBody, loaded : true})
	          //this.setState({essays: JSON.parse(result['Essays']), loaded: true})
	        },
	        // Note: it's important to handle errors here
	        // instead of a catch() block so that we don't swallow
	        // exceptions from actual bugs in components.
	        (error) => {
	          console.log(error)
	        }
	      )

	}

	render(){
		if(!this.state.loaded){
			return null;
		}
		/*
		return <div> 
				<NavigationHeader/>
				<div style={{textAlign : "center", paddingTop : "65px"}}><h1> Title Page </h1></div>
				<div style={{paddingLeft : "150px", paddingRight: "150px"}}><p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
				</div>
		</div>
		*/
		return <div>
				<NavigationHeader/>
					<div style= {{paddingTop : "65px"}}>
						<ReactMarkdown source={this.state.markdownBody} />
					</div>
				</div>

	}

}

export default Essay;