import React from 'react';
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';


class ProjectTile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {title:this.props.title, link: this.props.link, imageOpacity: "100%", textOpacity: "0", width: this.props.width, height: this.props.height, image: this.props.image}
  }

  getTitleStyle = () => {
  	return {
  		"fontWeight": "bold",
  		"justifyContent" : "center",
  		"verticalAlign" : "center",
  		"display" : "flex",
  		"alignItems" : "center",

  	}
  }

  highlightBox = () => {
  	this.setState( prevState => ({imageOpacity: "75%", textOpacity: "100"}))
  }
  
  unhighlightBox = () => {
  	this.setState( prevState => ({imageOpacity: "100%", textOpacity: "0"}))
  }

  getCardStyle = () => {
  	return {
  		width: this.state.width,
  		height: this.state.height,
  		//backgroundImage: `url(${"../ramen.jpg"})`,
      backgroundImage: `url(${this.state.image})`,
  		opacity: this.state.imageOpacity,
  		backgroundSize: "cover",
  		"fontWeight": "bold",
  		"justifyContent" : "center",
  		"verticalAlign" : "center",
  		"display" : "flex",
  		"alignItems" : "center",
  		}
  }


  getTextStyle = () => {
  	//console.log((this.state.height.substring(0, this.state.height.length - 2)).toInt() * .75).toString())
  	return {
  		"fontSize" : (parseInt(this.state.height.substring(0, this.state.height.length - 2)) * .1).toString() + "px",
  		"color": "rgba(0, 0, 0, " + this.state.textOpacity + ")"  		
  	}
  }

//<img src={this.state.image} style={{width: this.state.width, height: this.state.height}}/>
  render(){
    return (
      <Link href={this.state.link} underline="none">
      <Card style={this.getCardStyle()} 
            onMouseEnter={this.highlightBox}
            onMouseLeave={this.unhighlightBox}>
        <p style = {this.getTextStyle()}>
          {this.state.title}
        </p>
      </Card>
      </Link>
    );
  }
}

export default ProjectTile;
